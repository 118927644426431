.bulk-cert-modal {
  p {
    font-family: sans-serif;
    font-size: 0.85em;
    color: $chambray !important;
    padding: 0 !important;
  }

  .error-text {
    color: red !important;
  }

  .form-group--date {
    display: inline-flex;
    align-items: center;

    .react-datepicker-wrapper {
      width: 10rem;
    }

    label {
      margin-right: 1rem;
      max-width: 20rem;
      width: 100%;
    }

    @include breakpoint("up-to-tablet") {
      flex-wrap: wrap;
    }
  }

  // match labels of other inputs
  .date-label span {
    color: $label-color;
    font-family: $font-family-bold;
    font-size: 0.9rem;
  }

  .form-group--plainText.modal-h3 {
    margin-bottom: 0;

    label {
      margin-left: 0;
      margin-bottom: 0;
    }
  }
}

.project-contacts {
  .contacts {
    margin-left: 0;
  }

  .project-contacts-actions {
    display: flex;
    justify-content: flex-end;
    margin: 1em 0;
    clear: both;

    button {
      margin: 0;
    }
  }
}

.project-site-maps {
  display: flex;
  flex-direction: column;
  .card {
    margin-bottom: 1em;
  }

  @include breakpoint("up-to-tablet") {
    .search-control{
      width: 100%;
    }
  }
}

.map-container{
  width: 100%;
  margin-top: 1rem;
}
