.pagination {
  list-style: none;
  display: flex;
  align-items: stretch;
  flex-flow: row nowrap;
  padding-left: 0;
}

.pagination-item {
  & + & {
    margin-left: 0.33rem;
  }

  &:hover {
    background-color: $btn-hover-color-gray;
  }

  a {
    padding: 0.33rem 0.6rem;
    border: 1px solid $baliHai;
    border-radius: 4px;
    color: $baliHai;
    text-decoration: none;
  }

  &.active a {
    background-color: $pagination-active-color-gray;
  }
}
