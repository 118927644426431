.ClientDetailsFormUI{
    &__new-folder-list{
        display: flex;
        gap: 10px;
        padding-left: 25px;
        list-style: square inside;
        font-weight: 900;

        & span:hover{
            cursor: pointer;
        }
    }

    &__new-folder-input-container{
        height: 35px;
        gap: 8px;
        margin: 16px;

        & input{
            padding-left: 12px;
            border-radius: 4px;
        }
    }

    &__new-folder-add-button{
        background: #284260;
        color: white;

        &:hover{
            background: #3b628f;
        }
    }

    //this class is applied inside "lib-form" library to "checkboxGroup"
    &__new-folder-delete-icon{
        padding-left: 5px;

        &:hover{
            cursor: pointer;
        }
    }
}
