.month-view-layout .calendar{
    /* VARIABLES */
    --calendar-main-color: #1a8fff;
    --calendar-text-color: #777;
    --calendar-text-color-light: #ccc;
    --calendar-border-color: #eee;
    --calendar-bg-color: #f9f9f9;
    --calendar-neutral-color: #fff;
      
    /* GENERAL */
    
    & * {
      box-sizing: border-box;
    }
    
    & body {
      font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      font-size: 1em;
      font-weight: 300;
      line-height: 1.5;
      color: var(--calendar-text-color);
      background: var(--calendar-bg-color);
      position: relative;
    }
    
    & header {
      display: block;
      width: 100%;
      padding: 1.75em 0;
      border-bottom: 1px solid var(--calendar-border-color);
      background: var(--calendar-neutral-color);
    }
    
    & header #logo {
      font-size: 175%;
      text-align: center;
      color: var(--calendar-main-color);
      line-height: 1;
    }
    
    & header #logo .icon {
      padding-right: 0.25em;
    }
    
    & main {
      display: block;
      margin: 0 auto;
      margin-top: 5em;
      max-width: 50em;
    }

    .icon {
        font-family: "Material Icons", serif;
        font-style: normal;
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        white-space: nowrap;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: "liga";
    }
}
  
  
  

  
/* Calendar */  
.month-view-layout{
  .calendar {
    display: block;
    position: relative;
    width: 100%;
    background: var(--calendar-neutral-color);
    border: 1px solid var(--calendar-border-color);
    border-right: none;
  }
  
  .calendar__header{
    display: flex;
    justify-content: center;
  }
  
  .calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1rem 0;
    height: 60px;
    border-bottom: none;
    color: #385980;
  }
  
  .calendar .header .icon {
    cursor: pointer;
    transition: 0.15s ease-out;
  }
  
  .calendar .header .icon:hover {
    transition: 0.25s ease-out;
    color: var(--calendar-main-color);
  }
  
  .calendar .header .icon:first-of-type {
    margin-left: 1em;
  }
  
  .calendar .header .icon:last-of-type {
    margin-right: 1em;
  }
  
  .calendar .days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--calendar-text-color-light);
    font-size: 70%;
    padding: 0.75em 0;
    border-bottom: 1px solid var(--calendar-border-color);
  }
  
  .calendar .body .cell {
    position: relative;
    height: 18.93rem;
    border-right: 1px solid var(--calendar-border-color);
    cursor: pointer;
    background: var(--calendar-neutral-color);
    transition: 0.25s ease-out;
    font-size: 1.5em;
    -ms-overflow-style: none; /* Edge, Internet Explorer */
    scrollbar-width: none; /* Firefox */
    overflow-y: scroll;
    padding: 0;
  }
  
  .calendar .body .cell::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
  
  .calendar .body .cell:hover {
    background: var(--calendar-bg-color);
    transition: 0.5s ease-out;
  }
  
  .calendar .body .today {
    background: #eef8ff;
  }
  
  .calendar .body .row {
    border-bottom: 1px solid var(--calendar-border-color);
  }

  
  .calendar .body .cell:last-child {
    border-right: none;
  }
  
  .calendar .body .cell .number {
    background: #fff;
    color: #6D747E;
    font-size: 22px;
    position: sticky;
    top: -0.75px;
    margin-bottom: 0.5rem;
  }
  
  .calendar .body .disabled {
    color: var(--calendar-text-color-light);
    pointer-events: none;
  }
  
  .calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--calendar-main-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: -0.2em;
    right: -0.05em;
    transition: 0.25s ease-out;
    letter-spacing: -0.07em;
  }
  
  .calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
  }
  

  
  .renderHeader__center{
    position: relative;
    top: 3px;
    font-size: 16px;
    flex-basis: 5%;
  }
  
  .renderHeader__left{
    display: flex;
    align-items: center;
    gap: 0 1rem;
    font-size: 18px;
  }
  
  .renderHeader__right{
    display: flex;
    align-items: center;
    gap: 0 1rem;
    font-size: 18px;
  }
  
  .calendar__body{
    min-width: 600px;
    border-top: 1px solid #c4c3c3;
  }

  .render-cells{
    display: flex;
    flex-wrap: wrap;
    height: 305px;
  }

  .render-cell{
    color: #6D747E;
    flex-basis: 14.2857%;
    border: 1px solid #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}




  /* GRID */
  
  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .row-middle {
    align-items: center;
  }
  
  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  
  .col-start {
    justify-content: flex-start;
    text-align: left;
    }
  
  .col-center {
    justify-content: center;
    text-align: center;
  }
  
  .col-end {
    justify-content: flex-end;
    text-align: right;
  }


 // Classes to dynamically handle Icon Color
.missed-inspections-color{
  color: red;

  &:hover{
    cursor: pointer;
    color: rgb(206, 1, 1);
  }
}

.late-inspections-color{
  color: yellow;

  &:hover{
      cursor: pointer;
      color: rgb(240, 240, 28);
  }
}

.on-time-inspections-color{
  color: #4ADE86;

  &:hover{
      cursor: pointer;
      color: #3CB26C;
  }
}

.hide-icon{
  visibility: hidden;
}
