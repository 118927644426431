@import "../../../../scss/_mixins.scss"; // imported for disable scroll functionality
@import "../../../../scss/variables";

.precipitation-widget-section {
    th {
        border-bottom: 1px solid #c4c3c3;
    }

    td {
        font-family: 'ProximaNovaRegular' !important;
        color: #6D747E;
        font-size: 15px;
        height: 32px;
        line-height: 1.1;
        border-bottom: none;
    }

    .precip-checkbox {
        color: #DCDEE7;
    }

    .showmore-btn-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.heading-action-section {
    margin-top: 3px !important;
    // margin-left: 0 !important;

    .add-to-schedule-btn {
        background: #00A0DF !important;
        color: #FFF !important;
        height: 32px;

        :hover {
            background: #00A0DF !important;
            color: #FFF !important;
        }
    }
}

.table-fix-th-1 {
    height: 60px;
}

.table-fix-td-checkbox {
    padding: 0 !important;
}

.table-fix-th-1,
.table-fix-td-1 {
    padding: 2px !important;
    min-width: 165px !important;
}

.table-fix-th-precip-val, .table-fix-td-precip-val {
    padding: 2px !important;
    min-width: 110px !important;

    .rain-snow-identification{
        display: block;
        font-size: 10px;
    }
}


.table-fix-th-2,
.table-fix-td-2 {
    padding: 2px !important;
    min-width: 165px !important;
}

.table-fix-action{
    padding: 2px !important;
}

.start-inspection-btn, .view-project-btn {
    background-color: #4ADE86 !important;
    color: #FFF !important;
    border-radius: 4px;
    width: 110px;
    height: 30px;
    text-transform: capitalize !important;
    box-shadow: none !important;
    font-size: 12px;
    font-weight: bold;
    padding: 2px !important;
}

.view-project-btn {
    background-color: #00A0DF !important;
}

.precip-value-wrapper {
    cursor: pointer;

    .expand-precipitaion-icon {
        margin-left: 5px;
    }
}

.precip-widget-table-container {
    @include hide-scrollbar;
}

.precip-hr-data-contatiner{
    font-family: 'ProximaNovaRegular' !important;
    color: #6D747E;
    font-size: 15px;
    width: 180px;
    text-align: center;
    height: 250px;

    &__header{
        border-bottom: 1px solid #c4c3c3;
        height: 35px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__bodyItems{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
    }
}

.precipitation-body-data{
    &:nth-child(2n){
        background: $widget-alternate-row-highlight;
    }
}