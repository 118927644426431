.calendar-widget{
    font-family: 'ProximaNovaRegular';

    //header
    &__header{
        position: relative;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(12, 1fr);
        grid-auto-rows: 1fr 1fr;
        grid-template-areas: 
        "title title title title title title title . . . . ellipsis"
        "bars bars bars bars bars bars bars bars bars bars bars bars";
        margin-bottom: 10px;
        grid-row-gap: 6px;

        & span{
            border: 1px solid rgb(163, 163, 163);
            height: 32px;
            width: 37px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgb(80,101,126);

            &:hover{
                cursor: pointer;
            }
        }
    }

    &__title{
        grid-area: title;
        color: #284260;
        font-size: 22px;
        font-weight: 500;
    }

    &__ellipsis-button{
        grid-area: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__bars{
        grid-area: bars;
        display: flex;
        gap: 0.5rem;
        justify-content: space-between;

        & h3{
            height: 32px;
            min-width: 150px;
            color: #284260;
            // flex-basis: 45%;
            margin-bottom: 0;
        }

        & input{
            padding: 0 1rem;
            border: 1px solid rgb(163, 163, 163);
            height: 32px;
            border-radius: 4px;
            color: rgb(80,101,126);
            // flex-basis: 50%;
        }

        & select{
            padding: 0 0.5rem;
            border: 1px solid rgb(163, 163, 163);
            background: #fff;
            height: 32px;
            min-width: 150px;
            border-radius: 4px;
            color: rgb(80,101,126);
            flex-basis: 100%;
            // flex-basis: 45%;
        }
    }

    //body
    &__body{
        border: 0.1px solid rgb(221, 221, 221);
        border-radius: 5px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }

    //content
    &__content{
        @include hide-scrollbar;
        border-radius: 5px 5px 0 0;
        height: 400px;

        & .inspection-due__list-view{
            min-width: 600px;
        }
    }

    &__table-head{
        background: rgb(255,255,255);
        color: #385980;
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid rgb(196, 195, 195);
        position: sticky;
        top: 0;
        gap: 0.2rem;
        height: 60px;
        margin-bottom: 10px;  

        & > h2 {
            flex-basis: 10%;
        }

        & h3 {
            font-size: 15px;
            font-weight: 600;
            flex-basis: 20%;
            text-align: left;
            margin-top: 1rem;
            display: flex;
            align-items: flex-end;
            gap: 0.4rem;
        }

        & h3:first-child(){
            margin-left: 1rem;
        }

        & h3:not(:nth-child(n+4)){
            &:hover{
                cursor: pointer;
            }
        }

        & h3:nth-child(n+3){
            text-align: center;
            justify-content: center;
        }

        & h3:nth-child(4){
            flex-basis: 40%;
        }
    }

    &__table-body{
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 0.2rem;

        & li {
            font-family: "ProximaNovaRegular";
            font-size: 15px;
            color: #6D747E;
            text-align: left;
            flex-basis: 20%;
            margin: 0.5rem 0 0 0;            
            height: 32px;
            line-height: 1.1;
        }

        & li:first-child(){
            margin-left: 1rem;
        }

        & li:nth-child(n+3){
            text-align: center;
        }

        & li:not(:nth-child(n+3)){
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        & li:nth-child(4){
            flex: 40%;
        }

        & a{
            text-decoration: none;
        }

        & span{
            padding: 0.5rem 1rem;
            color: white;
            font-family: "ProximaNovaBold";
            font-size: 0.9rem;
            border-radius: 4px;
            width: 100px;
        }
    }

    //footer
    &__footer{
        border-top: 1px solid rgb(196, 195, 195);
        border-radius: 0 0 5px 5px;
        padding: 0.5rem;
        display: flex;
        justify-content: space-between;
        height: 60px;

        & ul{
            display: flex;
            grid-gap: 1rem;
            padding-left: 0.5rem;
            font-size: 14px;
            color: #6D747E;

            li{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            section:nth-child(1){
                li:nth-child(1) path{
                    color: #4ADE86;
                }

                li:nth-child(2) path{
                    color: yellow;;
                } 
            }

            section:nth-child(2){
                li:nth-child(1) path{
                    color: red;;
                }

                li:nth-child(2) path{
                    color: rgb(42, 212, 218);
                } 
            }

            p{
                display: inline;
            }
        }

        & span:nth-child(2){
            padding: 0.7em 1em;
            color: #284260;
            border: 0;
            font-family: "ProximaNovaBold";
            font-size: 1rem;
            border-radius: 4px;
            
            &:hover{
                cursor: pointer;
            }
        }
    }
}



//media query
@media (max-width: 899px){
    .calendar-widget{
        &__footer{
            justify-content: center;
            
            span{
                display: none;
            }
        }
    }
}